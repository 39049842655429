import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { baseurlwallet } from "./BaseUrl";
import jwt_decode from "jwt-decode";

function ForgotPassword() {
  const [startExamDisable, setStartExamDisable] = useState(false);
  const [showLoaderShow, setShowLoaderShow] = useState(false);
  const [profilePic, setProfilePic] = useState("");

  const [email, setEmail] = useState("");

  const resetPassword = async () => {
    setStartExamDisable(true);
    setShowLoaderShow(true);

    setTimeout(() => {
      setStartExamDisable(false);
      setShowLoaderShow(false);
    }, 5000);

    if (email === "") {
      document.getElementsByClassName("emailError")[0].innerText =
        "Email is required";
      document.getElementsByClassName("statusError")[0].innerText = "";
    } else {
      document.getElementsByClassName("emailError")[0].innerText = "";
    }

    const viewStudentDetails = async () => {
      // const tokens = localStorage.getItem("token");
      // sessions/student/forgetpassword
      let result = await fetch(
        `${baseurlwallet}/sessions/student/forgetpassword`,
        // let result = await fetch(`http://localhost:3000/students/${decoder.id}`,
        {
          method: "PUT",
          body: JSON.stringify({ email }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      result = await result.json();
      // setProfilePic(result.payload.student.profilePic);
      // localStorage.setItem("ProfilePic", result.payload.student.profilePic);
      // console.warn(result.payload.student.profilePic, "profilePIc")
    };

    if (email !== "") {
      viewStudentDetails();
      // let result = await fetch(`${baseurlwallet}/sessions/student/auth`, {
      //   method: "PUT",
      //   body: JSON.stringify({ email }),
      //   headers: {
      //     "Content-Type": "application/json",
      //     Accept: "application/json",
      //   },
      // })
      //   .then((result) => {
      //     // console.warn(result)
      //     // console.log('tokenn', result)
      //     if (result.status === 200) {
      //       return result.json();
      //     } else {
      //       document.getElementsByClassName("statusError")[0].innerText =
      //         "Username or password is incorrect";
      //     }
      //     throw result;
      //   })
      //   .then((data) => {
      //     localStorage.setItem("token", data.payload.token);
      //     console.log("=-=-==-=", data.payload.token);

      //     Navigate("/Dashboard");
      //   });
      // viewStudentDetails(data.payload.token);
      // result = await result.json();
      // console.warn("Login Result", result);
    }
  };

  return (
    <div class="container-fluid p-0">
      <div class="row g-0">
        <div class="col-lg-4">
          <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
            <div class="w-100">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <div>
                    <div class="text-center">
                      <div>
                        <a href="index.html" class=""></a>
                      </div>
                      <h4 class="font-size-18 mt-4">Reset Password</h4>
                    </div>
                    <div class="p-2 mt-5">
                      <div class="alert alert-success mb-4" role="alert">
                        Enter your Email and instructions will be sent to you!
                      </div>
                      <form class="">
                        <div class="auth-form-group-custom mb-4">
                          <i class="ri-mail-line auti-custom-input-icon"></i>

                          <input
                            type="email"
                            class="form-control"
                            id="useremail"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                        </div>
                        <div>
                          <p
                            className="emailError"
                            style={{ color: "red", fontWeight: "bold" }}
                          ></p>
                        </div>
                        <div>
                          <p
                            className="statusError"
                            style={{ color: "red", fontWeight: "bold" }}
                          ></p>
                        </div>

                        <div className="mt-4 text-center">
                          <button
                            class="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            onClick={() => resetPassword()}
                          >
                            Reset
                          </button>
                        </div>
                      </form>
                    </div>
                    <div class="mt-5 text-center">
                      <p>
                        Don't have an account ?{" "}
                        <Link to="/" class="fw-medium text-primary">
                          {" "}
                          Log in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="authentication-bg">
            <div class="bg-overlay"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ForgotPassword;
